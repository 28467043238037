<template>
	<a href="/">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" role="presentation" aria-label="logo"
			id="logo-part-one"
			width="25"
			height="103"
			viewBox="0 0 100 414.22">
			<g>
				<path d="M48.925,111.382a73.487,73.487,0,0,0-5.254-20.288,2.759,2.759,0,0,0-4.525-.78c-5.717,6.032-10.939,9.535-16.435,11.025S11.145,102.4,3.6,100A2.759,2.759,0,0,0,.02,102.931,73.481,73.481,0,0,0,5.735,123.1c6.26,13.593,15.891,20.138,27.852,18.933a2.742,2.742,0,0,0,.872-.236C45.389,136.791,50.391,126.275,48.925,111.382Zm-40.764,2.37c-.165-2.244,2.683-4.283,6.36-4.553s6.792,1.33,6.957,3.574-2.775,3.028-6.452,3.3-6.7-.072-6.865-2.317ZM31.656,133.19c-6.794,1.843-13.148.214-14.193-3.637a4.217,4.217,0,0,1-.135-.835c3.317,1.406,8.148,1.6,13.177.232s9.1-3.97,11.254-6.858a4.171,4.171,0,0,1,.306.789C43.111,126.731,38.45,131.348,31.656,133.19Zm5.7-23.178c-3.31,1.625-6.241,2.436-7.233.416s.888-4.975,4.2-6.6,6.8-1.3,7.789.716-1.443,3.844-4.753,5.469Z" transform="translate(4.128 -82.572)" fill="#964fcb"/>
				<path d="M280.686,131.413a2.759,2.759,0,0,0-2.328-1.167c-8.308.22-14.478-1-19.416-3.83s-8.929-7.429-12.56-14.467a2.759,2.759,0,0,0-4.609-.456c-.123.154-1.546,1.952-3.409,4.855a77.413,77.413,0,0,1,1.865,8.219c1.618-1.276,4.777-.623,7.366,1.612,2.791,2.41,3.861,5.744,2.39,7.447s-4.1.177-6.893-2.233a21.752,21.752,0,0,1-2.255-2.2,43.352,43.352,0,0,1-1.327,16.535,19.845,19.845,0,0,1,7.242,2.537c6.106,3.5,9.446,9.148,7.461,12.609a4.181,4.181,0,0,1-.495.686c-1.351-3.339-4.633-6.891-9.151-9.484a25.72,25.72,0,0,0-6.428-2.637c-.1.229-.205.455-.312.679a27.537,27.537,0,0,1-5.013,7.294,25.431,25.431,0,0,0,5.039,5.461,2.756,2.756,0,0,0,.783.449c11.266,4.195,22.24.3,31.737-11.266A73.526,73.526,0,0,0,281.008,134,2.76,2.76,0,0,0,280.686,131.413Zm-10.3,10.989c-.728,2.13-3.739,1.7-7.228.5s-6.135-2.692-5.407-4.822,4.147-2.89,7.635-1.7S271.117,140.272,270.389,142.4Z" transform="translate(-185.319 -99.662)" fill="#964fcb"/>
				<path d="M306.1,412.5h21.95v-7.064h-10.42A33.551,33.551,0,0,1,306.1,412.5Z" transform="translate(-245.435 -114.61)" fill="#18ad2f"/>
				<path d="M63.549,37.559A31.774,31.774,0,1,0,31.774,69.334,31.774,31.774,0,0,0,63.549,37.559ZM7.669,27.3l3.17-5.49A2.767,2.767,0,0,1,14.62,20.8l6.922,4a2.767,2.767,0,0,1,1.342,1.919l.724,4.137a2.768,2.768,0,0,1-1.817,3.092L17.9,35.3a2.767,2.767,0,0,1-2.294-.217l-6.921-4A2.767,2.767,0,0,1,7.669,27.3ZM23.652,44.4l-.775,4.05a2.768,2.768,0,0,1-1.334,1.877l-6.922,4a2.768,2.768,0,0,1-3.781-1.013L7.67,47.816a2.768,2.768,0,0,1,1.013-3.781l6.922-4a2.768,2.768,0,0,1,2.333-.2l3.945,1.441A2.767,2.767,0,0,1,23.652,44.4ZM37.712,60.8a2.768,2.768,0,0,1-2.768,2.768H28.6A2.767,2.767,0,0,1,25.837,60.8V52.8a2.767,2.767,0,0,1,.991-2.122l3.221-2.7a2.768,2.768,0,0,1,3.586.028l3.119,2.7a2.767,2.767,0,0,1,.958,2.094ZM26.468,37.559a5.307,5.307,0,1,1,5.307,5.307,5.307,5.307,0,0,1-5.307-5.307ZM37.713,22.316a2.766,2.766,0,0,1-.992,2.122l-3.22,2.7a2.767,2.767,0,0,1-3.586-.028L26.8,24.41a2.767,2.767,0,0,1-.958-2.094V14.324a2.767,2.767,0,0,1,2.767-2.767h6.339a2.768,2.768,0,0,1,2.768,2.768v7.992ZM39.9,30.722l.774-4.05A2.767,2.767,0,0,1,42.007,24.8l6.922-4a2.767,2.767,0,0,1,3.781,1.013l3.17,5.49a2.768,2.768,0,0,1-1.013,3.781l-6.922,4a2.768,2.768,0,0,1-2.333.2l-3.945-1.441A2.767,2.767,0,0,1,39.9,30.722Zm9.031,23.6-6.922-4a2.768,2.768,0,0,1-1.342-1.92l-.725-4.137a2.768,2.768,0,0,1,1.817-3.092l3.894-1.354a2.767,2.767,0,0,1,2.294.217l6.922,4a2.768,2.768,0,0,1,1.013,3.781l-3.17,5.491a2.767,2.767,0,0,1-3.783,1.014Z" transform="translate(17.431 228.593)" fill="#18ad2f"/>
				<path d="M36-1226.771v9.233l1.972.024c1.078.016,4.911.028,8.516.028h6.544v-3.154a15.644,15.644,0,0,1,.092-3.174c.043-.012,5.244-.017,11.546-.012l11.454.012-.383.163c-.468.2-2.248,1.07-2.448,1.2-.078.049-1.533.863-3.229,1.8-3.293,1.823-4.521,2.527-6.131,3.532-11.461,7.145-17.656,15.544-18.486,25.067-.043.435-.085,1.4-.092,2.136l-.021,1.343,11.881.012,11.881.008-.05-.293a38.465,38.465,0,0,1,.028-6.767,33.235,33.235,0,0,1,12.908-23.712,48.149,48.149,0,0,1,5.848-3.963l.972-.574V-1236H36Z" transform="translate(-12.606 1406.055)" fill="#fcd502"/>
				<path d="M53.27-1279.746a5.184,5.184,0,0,0-.77.221,1.238,1.238,0,0,1-.812.1,56.619,56.619,0,0,0-8.888-.149c-3.991.388-6.62,1.211-8.9,2.775-2.41,1.659-3.883,3.688-5.95,8.183-1.347,2.936-2.025,4.363-2.511,5.282a30.4,30.4,0,0,1-4.05,5.927,2.6,2.6,0,0,0-.4.525c.059.046,2.243-.262,3.674-.519,8.561-1.534,14.628-4.691,19.348-10.069a71.527,71.527,0,0,0,5.356-7.323c.954-1.4,2.234-3.146,2.276-3.116s-.343,1.789-.669,3.169a62.965,62.965,0,0,1-6.789,17.082,95.282,95.282,0,0,1-9.758,13.334c-2.461,2.9-7.381,8.535-7.448,8.535a7.637,7.637,0,0,1-.854-.382,25.5,25.5,0,0,0-7.59-2.405,25.588,25.588,0,0,0-4.829,0,14.643,14.643,0,0,0-7.389,3.312,17.487,17.487,0,0,0-5.186,8.07,11.27,11.27,0,0,0-.536,3.909,11.867,11.867,0,0,0,.921,4.985c2.276,5.73,8.227,9.92,15.767,11.125a28.827,28.827,0,0,0,10.042-.233,26.011,26.011,0,0,0,9.959-4.261,18.333,18.333,0,0,0,7.356-10.183,11.157,11.157,0,0,0,.55-3.79,7.493,7.493,0,0,0-.193-2.143q-1.418-5.658-10-7.2a33.707,33.707,0,0,0-3.925-.418,4.633,4.633,0,0,1-.9-.092c0-.03.569-.77,1.264-1.647,3.707-4.673,5.883-7.508,8.034-10.463,7.8-10.749,11.817-19.416,13.039-28.178a4.934,4.934,0,0,1,.293-1.355,1.273,1.273,0,0,1,1.824-.092,28.63,28.63,0,0,1,3.523,4.7c3.824,6.321,6.4,14.754,7.465,24.382a88.686,88.686,0,0,1,.469,12.29c-.05,1.462-.109,2.66-.126,2.674s-.511-.024-1.1-.078a38.841,38.841,0,0,0-5.59,0,15.387,15.387,0,0,0-7.674,2.6,9.542,9.542,0,0,0-3.046,3.4,13.1,13.1,0,0,0-.544,9.556c1.933,6.78,8.829,12.223,17.449,13.787a24.213,24.213,0,0,0,5.063.448,23.816,23.816,0,0,0,4.184-.281,24.419,24.419,0,0,0,10.444-4.029c5.6-3.975,8.16-9.842,6.881-15.78a16.956,16.956,0,0,0-3.046-6.905,12.214,12.214,0,0,0-5.682-3.981,15.147,15.147,0,0,0-5.206-.77,26.723,26.723,0,0,0-8.335,1.4c-1.372.442-1.247.454-1.247-.1,0-.263-.033-1.182-.083-2.053s-.117-2.453-.167-3.522c-.385-8.457-1.021-13.316-2.385-18.323-1.766-6.482-4.921-13.053-9.883-20.556-.795-1.205-.837-1.253-1.163-1.355a3.135,3.135,0,0,0-1.54-.018Z" transform="translate(3.539 1600.445)" fill="#d00"/>
				<path d="M33.257,0A33.257,33.257,0,1,1,0,33.257,33.257,33.257,0,0,1,33.257,0Z" transform="translate(16.972 80.734)" fill="#ff7800"/>
			</g>
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			id="logo-part-two"
			width="25"
			height="103"
			viewBox="0 0 100 414.22">
			<g>
				<path d="M48.925,111.382a73.487,73.487,0,0,0-5.254-20.288,2.759,2.759,0,0,0-4.525-.78c-5.717,6.032-10.939,9.535-16.435,11.025S11.145,102.4,3.6,100A2.759,2.759,0,0,0,.02,102.931,73.481,73.481,0,0,0,5.735,123.1c6.26,13.593,15.891,20.138,27.852,18.933a2.742,2.742,0,0,0,.872-.236C45.389,136.791,50.391,126.275,48.925,111.382Zm-40.764,2.37c-.165-2.244,2.683-4.283,6.36-4.553s6.792,1.33,6.957,3.574-2.775,3.028-6.452,3.3-6.7-.072-6.865-2.317ZM31.656,133.19c-6.794,1.843-13.148.214-14.193-3.637a4.217,4.217,0,0,1-.135-.835c3.317,1.406,8.148,1.6,13.177.232s9.1-3.97,11.254-6.858a4.171,4.171,0,0,1,.306.789C43.111,126.731,38.45,131.348,31.656,133.19Zm5.7-23.178c-3.31,1.625-6.241,2.436-7.233.416s.888-4.975,4.2-6.6,6.8-1.3,7.789.716-1.443,3.844-4.753,5.469Z" transform="translate(4.128 -82.572)" fill="#964fcb"/>
				<path d="M280.686,131.413a2.759,2.759,0,0,0-2.328-1.167c-8.308.22-14.478-1-19.416-3.83s-8.929-7.429-12.56-14.467a2.759,2.759,0,0,0-4.609-.456c-.123.154-1.546,1.952-3.409,4.855a77.413,77.413,0,0,1,1.865,8.219c1.618-1.276,4.777-.623,7.366,1.612,2.791,2.41,3.861,5.744,2.39,7.447s-4.1.177-6.893-2.233a21.752,21.752,0,0,1-2.255-2.2,43.352,43.352,0,0,1-1.327,16.535,19.845,19.845,0,0,1,7.242,2.537c6.106,3.5,9.446,9.148,7.461,12.609a4.181,4.181,0,0,1-.495.686c-1.351-3.339-4.633-6.891-9.151-9.484a25.72,25.72,0,0,0-6.428-2.637c-.1.229-.205.455-.312.679a27.537,27.537,0,0,1-5.013,7.294,25.431,25.431,0,0,0,5.039,5.461,2.756,2.756,0,0,0,.783.449c11.266,4.195,22.24.3,31.737-11.266A73.526,73.526,0,0,0,281.008,134,2.76,2.76,0,0,0,280.686,131.413Zm-10.3,10.989c-.728,2.13-3.739,1.7-7.228.5s-6.135-2.692-5.407-4.822,4.147-2.89,7.635-1.7S271.117,140.272,270.389,142.4Z" transform="translate(-185.319 -99.662)" fill="#964fcb"/>
				<path d="M306.1,412.5h21.95v-7.064h-10.42A33.551,33.551,0,0,1,306.1,412.5Z" transform="translate(-245.435 -114.61)" fill="#18ad2f"/>
				<path d="M63.549,37.559A31.774,31.774,0,1,0,31.774,69.334,31.774,31.774,0,0,0,63.549,37.559ZM7.669,27.3l3.17-5.49A2.767,2.767,0,0,1,14.62,20.8l6.922,4a2.767,2.767,0,0,1,1.342,1.919l.724,4.137a2.768,2.768,0,0,1-1.817,3.092L17.9,35.3a2.767,2.767,0,0,1-2.294-.217l-6.921-4A2.767,2.767,0,0,1,7.669,27.3ZM23.652,44.4l-.775,4.05a2.768,2.768,0,0,1-1.334,1.877l-6.922,4a2.768,2.768,0,0,1-3.781-1.013L7.67,47.816a2.768,2.768,0,0,1,1.013-3.781l6.922-4a2.768,2.768,0,0,1,2.333-.2l3.945,1.441A2.767,2.767,0,0,1,23.652,44.4ZM37.712,60.8a2.768,2.768,0,0,1-2.768,2.768H28.6A2.767,2.767,0,0,1,25.837,60.8V52.8a2.767,2.767,0,0,1,.991-2.122l3.221-2.7a2.768,2.768,0,0,1,3.586.028l3.119,2.7a2.767,2.767,0,0,1,.958,2.094ZM26.468,37.559a5.307,5.307,0,1,1,5.307,5.307,5.307,5.307,0,0,1-5.307-5.307ZM37.713,22.316a2.766,2.766,0,0,1-.992,2.122l-3.22,2.7a2.767,2.767,0,0,1-3.586-.028L26.8,24.41a2.767,2.767,0,0,1-.958-2.094V14.324a2.767,2.767,0,0,1,2.767-2.767h6.339a2.768,2.768,0,0,1,2.768,2.768v7.992ZM39.9,30.722l.774-4.05A2.767,2.767,0,0,1,42.007,24.8l6.922-4a2.767,2.767,0,0,1,3.781,1.013l3.17,5.49a2.768,2.768,0,0,1-1.013,3.781l-6.922,4a2.768,2.768,0,0,1-2.333.2l-3.945-1.441A2.767,2.767,0,0,1,39.9,30.722Zm9.031,23.6-6.922-4a2.768,2.768,0,0,1-1.342-1.92l-.725-4.137a2.768,2.768,0,0,1,1.817-3.092l3.894-1.354a2.767,2.767,0,0,1,2.294.217l6.922,4a2.768,2.768,0,0,1,1.013,3.781l-3.17,5.491a2.767,2.767,0,0,1-3.783,1.014Z" transform="translate(17.431 228.593)" fill="#18ad2f"/>
				<path d="M36-1226.771v9.233l1.972.024c1.078.016,4.911.028,8.516.028h6.544v-3.154a15.644,15.644,0,0,1,.092-3.174c.043-.012,5.244-.017,11.546-.012l11.454.012-.383.163c-.468.2-2.248,1.07-2.448,1.2-.078.049-1.533.863-3.229,1.8-3.293,1.823-4.521,2.527-6.131,3.532-11.461,7.145-17.656,15.544-18.486,25.067-.043.435-.085,1.4-.092,2.136l-.021,1.343,11.881.012,11.881.008-.05-.293a38.465,38.465,0,0,1,.028-6.767,33.235,33.235,0,0,1,12.908-23.712,48.149,48.149,0,0,1,5.848-3.963l.972-.574V-1236H36Z" transform="translate(-12.606 1406.055)" fill="#fcd502"/>
				<path d="M53.27-1279.746a5.184,5.184,0,0,0-.77.221,1.238,1.238,0,0,1-.812.1,56.619,56.619,0,0,0-8.888-.149c-3.991.388-6.62,1.211-8.9,2.775-2.41,1.659-3.883,3.688-5.95,8.183-1.347,2.936-2.025,4.363-2.511,5.282a30.4,30.4,0,0,1-4.05,5.927,2.6,2.6,0,0,0-.4.525c.059.046,2.243-.262,3.674-.519,8.561-1.534,14.628-4.691,19.348-10.069a71.527,71.527,0,0,0,5.356-7.323c.954-1.4,2.234-3.146,2.276-3.116s-.343,1.789-.669,3.169a62.965,62.965,0,0,1-6.789,17.082,95.282,95.282,0,0,1-9.758,13.334c-2.461,2.9-7.381,8.535-7.448,8.535a7.637,7.637,0,0,1-.854-.382,25.5,25.5,0,0,0-7.59-2.405,25.588,25.588,0,0,0-4.829,0,14.643,14.643,0,0,0-7.389,3.312,17.487,17.487,0,0,0-5.186,8.07,11.27,11.27,0,0,0-.536,3.909,11.867,11.867,0,0,0,.921,4.985c2.276,5.73,8.227,9.92,15.767,11.125a28.827,28.827,0,0,0,10.042-.233,26.011,26.011,0,0,0,9.959-4.261,18.333,18.333,0,0,0,7.356-10.183,11.157,11.157,0,0,0,.55-3.79,7.493,7.493,0,0,0-.193-2.143q-1.418-5.658-10-7.2a33.707,33.707,0,0,0-3.925-.418,4.633,4.633,0,0,1-.9-.092c0-.03.569-.77,1.264-1.647,3.707-4.673,5.883-7.508,8.034-10.463,7.8-10.749,11.817-19.416,13.039-28.178a4.934,4.934,0,0,1,.293-1.355,1.273,1.273,0,0,1,1.824-.092,28.63,28.63,0,0,1,3.523,4.7c3.824,6.321,6.4,14.754,7.465,24.382a88.686,88.686,0,0,1,.469,12.29c-.05,1.462-.109,2.66-.126,2.674s-.511-.024-1.1-.078a38.841,38.841,0,0,0-5.59,0,15.387,15.387,0,0,0-7.674,2.6,9.542,9.542,0,0,0-3.046,3.4,13.1,13.1,0,0,0-.544,9.556c1.933,6.78,8.829,12.223,17.449,13.787a24.213,24.213,0,0,0,5.063.448,23.816,23.816,0,0,0,4.184-.281,24.419,24.419,0,0,0,10.444-4.029c5.6-3.975,8.16-9.842,6.881-15.78a16.956,16.956,0,0,0-3.046-6.905,12.214,12.214,0,0,0-5.682-3.981,15.147,15.147,0,0,0-5.206-.77,26.723,26.723,0,0,0-8.335,1.4c-1.372.442-1.247.454-1.247-.1,0-.263-.033-1.182-.083-2.053s-.117-2.453-.167-3.522c-.385-8.457-1.021-13.316-2.385-18.323-1.766-6.482-4.921-13.053-9.883-20.556-.795-1.205-.837-1.253-1.163-1.355a3.135,3.135,0,0,0-1.54-.018Z" transform="translate(3.539 1600.445)" fill="#d00"/>
				<path d="M33.257,0A33.257,33.257,0,1,1,0,33.257,33.257,33.257,0,0,1,33.257,0Z" transform="translate(16.972 80.734)" fill="#00e636"/>
			</g>
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			id="logo-part-three"
			width="25"
			height="103"
			viewBox="0 0 100 414.22">
			<g>
				<path d="M48.925,111.382a73.487,73.487,0,0,0-5.254-20.288,2.759,2.759,0,0,0-4.525-.78c-5.717,6.032-10.939,9.535-16.435,11.025S11.145,102.4,3.6,100A2.759,2.759,0,0,0,.02,102.931,73.481,73.481,0,0,0,5.735,123.1c6.26,13.593,15.891,20.138,27.852,18.933a2.742,2.742,0,0,0,.872-.236C45.389,136.791,50.391,126.275,48.925,111.382Zm-40.764,2.37c-.165-2.244,2.683-4.283,6.36-4.553s6.792,1.33,6.957,3.574-2.775,3.028-6.452,3.3-6.7-.072-6.865-2.317ZM31.656,133.19c-6.794,1.843-13.148.214-14.193-3.637a4.217,4.217,0,0,1-.135-.835c3.317,1.406,8.148,1.6,13.177.232s9.1-3.97,11.254-6.858a4.171,4.171,0,0,1,.306.789C43.111,126.731,38.45,131.348,31.656,133.19Zm5.7-23.178c-3.31,1.625-6.241,2.436-7.233.416s.888-4.975,4.2-6.6,6.8-1.3,7.789.716-1.443,3.844-4.753,5.469Z" transform="translate(4.128 -82.572)" fill="#964fcb"/>
				<path d="M280.686,131.413a2.759,2.759,0,0,0-2.328-1.167c-8.308.22-14.478-1-19.416-3.83s-8.929-7.429-12.56-14.467a2.759,2.759,0,0,0-4.609-.456c-.123.154-1.546,1.952-3.409,4.855a77.413,77.413,0,0,1,1.865,8.219c1.618-1.276,4.777-.623,7.366,1.612,2.791,2.41,3.861,5.744,2.39,7.447s-4.1.177-6.893-2.233a21.752,21.752,0,0,1-2.255-2.2,43.352,43.352,0,0,1-1.327,16.535,19.845,19.845,0,0,1,7.242,2.537c6.106,3.5,9.446,9.148,7.461,12.609a4.181,4.181,0,0,1-.495.686c-1.351-3.339-4.633-6.891-9.151-9.484a25.72,25.72,0,0,0-6.428-2.637c-.1.229-.205.455-.312.679a27.537,27.537,0,0,1-5.013,7.294,25.431,25.431,0,0,0,5.039,5.461,2.756,2.756,0,0,0,.783.449c11.266,4.195,22.24.3,31.737-11.266A73.526,73.526,0,0,0,281.008,134,2.76,2.76,0,0,0,280.686,131.413Zm-10.3,10.989c-.728,2.13-3.739,1.7-7.228.5s-6.135-2.692-5.407-4.822,4.147-2.89,7.635-1.7S271.117,140.272,270.389,142.4Z" transform="translate(-185.319 -99.662)" fill="#964fcb"/>
				<path d="M306.1,412.5h21.95v-7.064h-10.42A33.551,33.551,0,0,1,306.1,412.5Z" transform="translate(-245.435 -114.61)" fill="#18ad2f"/>
				<path d="M63.549,37.559A31.774,31.774,0,1,0,31.774,69.334,31.774,31.774,0,0,0,63.549,37.559ZM7.669,27.3l3.17-5.49A2.767,2.767,0,0,1,14.62,20.8l6.922,4a2.767,2.767,0,0,1,1.342,1.919l.724,4.137a2.768,2.768,0,0,1-1.817,3.092L17.9,35.3a2.767,2.767,0,0,1-2.294-.217l-6.921-4A2.767,2.767,0,0,1,7.669,27.3ZM23.652,44.4l-.775,4.05a2.768,2.768,0,0,1-1.334,1.877l-6.922,4a2.768,2.768,0,0,1-3.781-1.013L7.67,47.816a2.768,2.768,0,0,1,1.013-3.781l6.922-4a2.768,2.768,0,0,1,2.333-.2l3.945,1.441A2.767,2.767,0,0,1,23.652,44.4ZM37.712,60.8a2.768,2.768,0,0,1-2.768,2.768H28.6A2.767,2.767,0,0,1,25.837,60.8V52.8a2.767,2.767,0,0,1,.991-2.122l3.221-2.7a2.768,2.768,0,0,1,3.586.028l3.119,2.7a2.767,2.767,0,0,1,.958,2.094ZM26.468,37.559a5.307,5.307,0,1,1,5.307,5.307,5.307,5.307,0,0,1-5.307-5.307ZM37.713,22.316a2.766,2.766,0,0,1-.992,2.122l-3.22,2.7a2.767,2.767,0,0,1-3.586-.028L26.8,24.41a2.767,2.767,0,0,1-.958-2.094V14.324a2.767,2.767,0,0,1,2.767-2.767h6.339a2.768,2.768,0,0,1,2.768,2.768v7.992ZM39.9,30.722l.774-4.05A2.767,2.767,0,0,1,42.007,24.8l6.922-4a2.767,2.767,0,0,1,3.781,1.013l3.17,5.49a2.768,2.768,0,0,1-1.013,3.781l-6.922,4a2.768,2.768,0,0,1-2.333.2l-3.945-1.441A2.767,2.767,0,0,1,39.9,30.722Zm9.031,23.6-6.922-4a2.768,2.768,0,0,1-1.342-1.92l-.725-4.137a2.768,2.768,0,0,1,1.817-3.092l3.894-1.354a2.767,2.767,0,0,1,2.294.217l6.922,4a2.768,2.768,0,0,1,1.013,3.781l-3.17,5.491a2.767,2.767,0,0,1-3.783,1.014Z" transform="translate(17.431 228.593)" fill="#18ad2f"/>
				<path d="M36-1226.771v9.233l1.972.024c1.078.016,4.911.028,8.516.028h6.544v-3.154a15.644,15.644,0,0,1,.092-3.174c.043-.012,5.244-.017,11.546-.012l11.454.012-.383.163c-.468.2-2.248,1.07-2.448,1.2-.078.049-1.533.863-3.229,1.8-3.293,1.823-4.521,2.527-6.131,3.532-11.461,7.145-17.656,15.544-18.486,25.067-.043.435-.085,1.4-.092,2.136l-.021,1.343,11.881.012,11.881.008-.05-.293a38.465,38.465,0,0,1,.028-6.767,33.235,33.235,0,0,1,12.908-23.712,48.149,48.149,0,0,1,5.848-3.963l.972-.574V-1236H36Z" transform="translate(-12.606 1406.055)" fill="#fcd502"/>
				<path d="M53.27-1279.746a5.184,5.184,0,0,0-.77.221,1.238,1.238,0,0,1-.812.1,56.619,56.619,0,0,0-8.888-.149c-3.991.388-6.62,1.211-8.9,2.775-2.41,1.659-3.883,3.688-5.95,8.183-1.347,2.936-2.025,4.363-2.511,5.282a30.4,30.4,0,0,1-4.05,5.927,2.6,2.6,0,0,0-.4.525c.059.046,2.243-.262,3.674-.519,8.561-1.534,14.628-4.691,19.348-10.069a71.527,71.527,0,0,0,5.356-7.323c.954-1.4,2.234-3.146,2.276-3.116s-.343,1.789-.669,3.169a62.965,62.965,0,0,1-6.789,17.082,95.282,95.282,0,0,1-9.758,13.334c-2.461,2.9-7.381,8.535-7.448,8.535a7.637,7.637,0,0,1-.854-.382,25.5,25.5,0,0,0-7.59-2.405,25.588,25.588,0,0,0-4.829,0,14.643,14.643,0,0,0-7.389,3.312,17.487,17.487,0,0,0-5.186,8.07,11.27,11.27,0,0,0-.536,3.909,11.867,11.867,0,0,0,.921,4.985c2.276,5.73,8.227,9.92,15.767,11.125a28.827,28.827,0,0,0,10.042-.233,26.011,26.011,0,0,0,9.959-4.261,18.333,18.333,0,0,0,7.356-10.183,11.157,11.157,0,0,0,.55-3.79,7.493,7.493,0,0,0-.193-2.143q-1.418-5.658-10-7.2a33.707,33.707,0,0,0-3.925-.418,4.633,4.633,0,0,1-.9-.092c0-.03.569-.77,1.264-1.647,3.707-4.673,5.883-7.508,8.034-10.463,7.8-10.749,11.817-19.416,13.039-28.178a4.934,4.934,0,0,1,.293-1.355,1.273,1.273,0,0,1,1.824-.092,28.63,28.63,0,0,1,3.523,4.7c3.824,6.321,6.4,14.754,7.465,24.382a88.686,88.686,0,0,1,.469,12.29c-.05,1.462-.109,2.66-.126,2.674s-.511-.024-1.1-.078a38.841,38.841,0,0,0-5.59,0,15.387,15.387,0,0,0-7.674,2.6,9.542,9.542,0,0,0-3.046,3.4,13.1,13.1,0,0,0-.544,9.556c1.933,6.78,8.829,12.223,17.449,13.787a24.213,24.213,0,0,0,5.063.448,23.816,23.816,0,0,0,4.184-.281,24.419,24.419,0,0,0,10.444-4.029c5.6-3.975,8.16-9.842,6.881-15.78a16.956,16.956,0,0,0-3.046-6.905,12.214,12.214,0,0,0-5.682-3.981,15.147,15.147,0,0,0-5.206-.77,26.723,26.723,0,0,0-8.335,1.4c-1.372.442-1.247.454-1.247-.1,0-.263-.033-1.182-.083-2.053s-.117-2.453-.167-3.522c-.385-8.457-1.021-13.316-2.385-18.323-1.766-6.482-4.921-13.053-9.883-20.556-.795-1.205-.837-1.253-1.163-1.355a3.135,3.135,0,0,0-1.54-.018Z" transform="translate(3.539 1600.445)" fill="#d00"/>
				<path d="M33.257,0A33.257,33.257,0,1,1,0,33.257,33.257,33.257,0,0,1,33.257,0Z" transform="translate(16.972 80.734)" fill="#00bdfa"/>
			</g>
		</svg>
	</a>
</template>

<script>
	export default
	{
		name: 'Logo'
	}
</script>

<style scoped>
	a 
	{
		display: block;
		text-decoration: none;
		overflow: hidden;
		outline: none;
		width: 78px;
		height: 25px;
		margin: auto;

		border: 2px solid var(--secondary);
		border-radius: 4px;
		
		transform: scale(1.5) translateY(15px);
		transition: border ease-in-out 0.25s, /* darmode transition */
			transform 1.2s cubic-bezier(0.82, 0.01, 0.45, 1); /* loading transition */
	}

	[v-focus-visible=true] a:focus
	{
		box-shadow: 0 0 0 2px var(--background), 0 0 0 3.5px var(--primary);
	}

	.entered a,
	.done a
	{
		/* shrink down when searched */
		transform: none;
	}


	/*==========================================================================
		animations
	==========================================================================*/

	.entered svg 
	{
		animation: spin 0.2s linear infinite;
	}

	svg 
	{
		transform: translateY(-16.3px);
		animation: finish 0.8s cubic-bezier(0, 0.7, 0.39, 1.35);
	}

	#logo-part-one
	{
		animation-delay: 0.05s;
	}

	#logo-part-two
	{
		animation-delay: 0s;
	}

	#logo-part-three
	{
		animation-delay: 0.1s;
	}

	@keyframes spin 
	{
		0% { transform: translateY(-103px); }
		100% { transform: translateY(0px); }
	}

	@keyframes finish 
	{
		0% { transform: translateY(-100px); }
		100% { transform: translateY(-16.3px); }
	}
</style>
